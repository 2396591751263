.title1 {
  /* font-size: 48px !important; */
  font-size: 40px !important;
  color: white;
  text-shadow: 1px 1px 10px #000;
}

.title2 {
  /* font-size: 34px !important; */
  font-size: 28px !important;
  color: white;
  text-shadow: 1px 1px 10px #000;
}

.CookieConsent {
  z-index: 9999 !important;
}

.ellipsis-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popular-position-active {
  color: white !important;
  background-color: #262346 !important;
}

.popular-position-title {
  padding-left: 8px;
  font-size: 1em !important;
  font-weight: 400 !important;
}

.cover-image {
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
}

.not-cover-image {
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
}

.add-resume-title {
  word-wrap: break-word;
  font-size: 22px !important;
  font-weight: 600 !important;
  color: white;
}

/* Job Seeker */
.job-seeker-title {
  word-wrap: break-word;
  font-size: 26px !important;
  font-weight: 600 !important;
  color: white;
}

.job-seeker-desc {
  word-wrap: break-word;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: white;
}

.job-seeker-box {
  padding: 60px 10px 30px 10px;
}

/* Site Map */
.image-size-footer {
  width: 8vw;
  height: 8vw;
}

.site-map-head {
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.site-map-label {
  font-size: 14px !important;
  color: black;
}

.site-map-label:hover {
  color: #0366d6;
  text-decoration: underline;
  cursor: pointer;
}

.zoom-image-banner {
  transition: transform 0.2s;
}

.zoom-image-banner:hover {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(1.1);
}

/* Search Job Scroll */
.custom-scroll-bar-search-job::-webkit-scrollbar {
  width: 5px;
}

.custom-scroll-bar-search-job::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #c1c1c1;
  border-radius: 5px;
}

.custom-scroll-bar-search-job::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
}

.custom-scroll-bar-search-job::-webkit-scrollbar-thumb:hover {
  background: #929292;
}

@media (max-width: 1000px) {
  .job-seeker-box {
    padding: 30px 5px 15px 5px;
  }

  .title1 {
    font-size: 34px !important;
  }

  .title2 {
    font-size: 20px !important;
  }

  .image-size-footer {
    width: 10vw;
    height: 10vw;
  }
}

@media (max-width: 767px) {
  .popular-position-title {
    padding-left: 0px;
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .add-resume-title {
    font-size: 16px !important;
    font-weight: 600 !important;
  }

  .job-seeker-box {
    padding: 15px 3px 8px 8px;
  }
}

@media (max-width: 400px) {
  .image-size-footer {
    width: 15vw;
    height: 15vw;
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("assets/fonts/Prompt/css/font.css");
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

 .info-cover {
    transition: background-size .5s;
  }
  .info-cover-detail{
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
 .info-cover:hover >.info-cover-detail {
    background-size: 100%;

  }

  .fade-bg-color{
    padding: 2em;
    border-radius: 1em;
    position: relative; 
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30vh, rgba(255,255,255,0) 50vh, rgba(255,255,255,0) 100%);
    /* background: "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 15%, rgba(255,255,255,0) 100%)", */
  }

  .fade-bg-color:before {
    border-radius: 1em;
    content: "";
    z-index: -1;
    position: absolute;
    top: 5px;
    right: -2px;
    bottom: -2px;
    left: 0px;
    background: linear-gradient(#808080, rgba(255, 255, 255, 0) 40vh);
    /* background: linear-gradient(to top left, #ff3d00 0%, #0400ff 100% ); */
    /* transform: translate3d(0px, 20px, 0) scale(0.95); */
    filter: blur(5px);
    /* opacity: var(0.7); */
    /* transition: opacity 0.3s; */
  }
  
  /* .fade-bg-color::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
  } */
  .txt-underline :hover {
    text-decoration: underline;
  }

  p.pDescNews {  
    white-space: pre-wrap; 
   /* word-break: break-word; */
   text-overflow: ",";     
    /* word-break: break-all;  */
}
.image-companyBanner{
  width: "100%" ;
  position: relative;

}
.block-ui-overlay{
  z-index: 9999;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
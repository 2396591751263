/* Bachground Image layout */
.MenuFront {
  font-size: 13px !important;
}

#MenuFont {
  /* font-family: "Manrope", Sans-serif; */
  font-family: "Prompt", Sans-serif;
}

.box-img {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 106vw;
}

.bg-top {
  position: absolute;
  z-index: 0;
  width: 100%;
}

.bg-top img {
  width: 100%;
}

/* End Bachground Image layout */

/* TH to Eng */
.button {
  background: #8CC0DE;
  margin: auto;
  height: 2.500em;
  width: 5%;
  margin-right: 2%;
  overflow: hidden;
  text-align: center;
  transition: .3s;
  cursor: pointer;
  border-radius: 5%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, .2);
}

/* both language */
.btnTwo {
  position: relative;
  width: 12.500vw;
  height: 5.625em;
  margin-top: -4.563em;
  padding-top: 0.125em;
  background: #ffe28c;
  left: -15.625vw;
  transition: .3s;
}

/* first language */
.btnText {
  color: rgb(8 39 123);
  transition: .3s;
  margin-top: 10px;
}

/* second language */
.btnText2 {
  margin-top: 47px;
  margin-right: -8.125vw;
  color: rgb(8 39 123);
}

.button:hover .btnTwo {
  left: -9.125vw;
}

.button:hover .btnText {
  margin-left: 4.063vw;
}

.button:active {
  background: #59abda;
}

/* ------------------------------------------------------------------------ */

/* ENG to TH */
.button2 {
  background: #ffe28c;
  margin: auto;
  height: 2.500em;
  width: 5%;
  margin-right: 2%;
  overflow: hidden;
  text-align: center;
  transition: .3s;
  cursor: pointer;
  border-radius: 5%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, .2);
}

.btnTwoEng {
  position: relative;
  width: 12.500vw;
  height: 5.625em;
  margin-top: -4.563em;
  padding-top: 0.125em;
  background: #8CC0DE;
  right: -30vw;
  transition: .3s;
}

.btnTextEng {
  color: rgb(8 39 123);
  transition: .3s;
  margin-top: 10px;
}

.btnText2Eng {
  margin-top: 47px;
  margin-left: -8.125vw;
  color: rgb(8 39 123);
}

.button2:hover .btnTwoEng {
  right: -5vw;
}

.button2:hover .btnTextEng {
  margin-right: 4.063em;
}

.button2:active {
  background: #fad565;
}
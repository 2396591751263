.css-1emto0y-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
}

.css-pflgkk .MuiOutlinedInput-root .MuiAutocomplete-input {
    margin-top: -19px !important;
}

.css-1wdbd77 {
    top: -8px;
}

.css-5bzh4d-MuiFormLabel-root-MuiInputLabel-root {
    margin-top: -20% !important;
}

.css-1yh4bk9-MuiButtonBase-root-MuiButton-root {
    font-size: 13px !important;
    padding: 22px 15px !important;
}

.css-7v2jkm {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    min-width: 0px;
    width: 100%;
    background-color: white;
}

.css-1wdbd77 {
    color: rgba(0, 0, 0, 0.6);
    font-size: 1em;
    font-weight: 400;
    line-height: 1.4375em;
    padding: 0px;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
    position: absolute;
    left: 0px;
    top: -8px !important;
    transform: translate(14px, 16px) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
    font-family: Manrope, sans-serif !important;
}

.css-16sx77j {
    padding: none !important;
}

.css-pflgkk .MuiOutlinedInput-root {
    /* padding: 9px; */
}

.css-1xbt4zg {
    padding: 7px 15px;
}
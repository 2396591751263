@media (min-width: 1000px) {
    .apply-bg-color {
        border-radius: 1em;
        position: relative;
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 30vh, rgba(255, 255, 255, 0) 50vh, rgba(255, 255, 255, 0) 100%);
        background-color: white;
        padding: 35px;
    }
    .apply-button {
        font-size: 13px;
        width: 140px;
        height: 40px;
    }
    .apply-buttonProfile {
        font-size: 13px;
        width: 180px;
        height: 40px;
    }
}

@media (max-width: 1000px) {
    .apply-bg-color {
        border-radius: 1em;
        position: relative;
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 30vh, rgba(255, 255, 255, 0) 50vh, rgba(255, 255, 255, 0) 100%);
        background-color: white;
        margin-top: 15px;
    }
    .apply-button {
        font-size: 13px;
        width: 140px;
        height: 40px;
    }
    .apply-buttonProfile {
        font-size: 13px;
        width: 180px;
        height: 40px;
    }
}

@media (max-width: 400px) {
    .apply-button {
        font-size: 8px;
        width: 110px;
        height: 40px;
    }
    .apply-buttonProfile {
        font-size: 8px;
        width: 170px;
        height: 40px;
    }
}
.MuiTable-root {
  border-collapse: separate !important;
}

td.MuiTableCell-root {
  background-color: #fff;
}

.MuiTableRow-root th:first-child {
  border-top-left-radius: 20px;
}

.MuiTableRow-root th:last-child {
  border-top-right-radius: 20px;
}

.MuiTableBody-root > tr:last-child > td:first-child {
  border-bottom-left-radius: 20px;
}

.MuiTableBody-root > tr:last-child > td:last-child {
  border-bottom-right-radius: 20px;
}

.css-1jyw0qc-MuiGrid-root {
  margin-top: -0 !important;
  margin-left: -0 !important;
}

@media (min-width: 1536px) {
  .divMyPageMiddle {
    flex-basis: 32% !important;
    max-width: 32% !important;
  }
}
.btn-scroll {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 1;
  user-select: none;
  margin: 3px 0;
  text-decoration: none;
}

.btn-scroll > .scroll-label {
  color: #ffffff;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
  font-weight: 600;
  text-align: right;
}

.btn-scroll > .scroll-label > .label-1 {
  font-size: 1em;
}

.btn-scroll > .scroll-label > .label-2 {
  font-size: 0.7em;
}

.btn-scroll > .scroll-arrow {
  margin-left: 10px;
  padding: 15px 5px;
  font-size: 1.25em;
  color: #ffffff;
  border-radius: 50em;
  background-color: #0067ff;
  transition: background-color 0.3s;
}

.btn-scroll:hover {
  text-decoration: none;
  cursor: pointer;
}

.btn-scroll:hover > .scroll-arrow {
  background-color: #1b138f;
}

#SCROLL {
  z-index: 15;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.backgroundImg {
  background-color: rgba(0,0,0, 0.4);
}
@font-face {
    font-family: "Prompt";
    /* font-family: "Manrope",Sans-serif; */
    src: url(../font/Prompt-Thin.ttf) format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Prompt";
    /* font-family: "Manrope",Sans-serif; */
    src: url(../font/Prompt-ThinItalic.ttf) format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Prompt";
    /* font-family: "Manrope",Sans-serif; */
    src: url(../font/Prompt-ExtraLight.ttf) format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Prompt";
    /* font-family: "Manrope",Sans-serif; */
    src: url(../font/Prompt-ExtraLightItalic.ttf) format("truetype");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "Prompt";
    /* font-family: "Manrope",Sans-serif; */
    src: url(../font/Prompt-Light.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Prompt";
    /* font-family: "Manrope",Sans-serif; */
    src: url(../font/Prompt-LightItalic.ttf) format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Prompt";
    /* font-family: "Manrope",Sans-serif; */
    src: url(../font/Prompt-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Prompt";
    /* font-family: "Manrope",Sans-serif; */
    src: url(../font/Prompt-Italic.ttf) format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Prompt";
    /* font-family: "Manrope",Sans-serif; */
    src: url(../font/Prompt-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Prompt";
    /* font-family: "Manrope",Sans-serif; */
    src: url(../font/Prompt-MediumItalic.ttf) format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Prompt";
    /* font-family: "Manrope",Sans-serif; */
    src: url(../font/Prompt-SemiBold.ttf) format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Prompt";
    /* font-family: "Manrope",Sans-serif; */
    src: url(../font/Prompt-SemiBoldItalic.ttf) format("truetype");
    font-weight: 600;
    font-style: italic;
}

.Manrope {
    font-family: Prompt, sans-serif !important;
    /* font-family: "Manrope", sans-serif !important; */
}
.box {
  flex-grow: 1;
  margin: 10px;
  /* Adjust as needed */
  padding: 20px;
  /* Adjust as needed */
  border-radius: 30px;
  box-sizing: border-box;
  /* Ensures padding and border are included in the width and height */
  width: 300px;
  background: white;
  box-shadow: 0em 0.2em 0.2em 0px rgb(162 162 162);
}

.box-content {
  flex: 1;
}

.plan {
  border-radius: 30px;
  color: #9fa0a0;
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
}

.plan .pricing {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  border-radius: 99em 0 0 99em;
  display: flex;
  align-items: center;
  padding: 0.625em 0.75em;
  font-size: 1.25rem;
  font-weight: 600;
  color: #ebf1fd;
}

.plan .pricing small {
  color: #707a91;
  font-size: 0.75em;
  margin-left: 0.25em;
}

.plan .title {
  font-weight: 600;
  font-size: 2rem;
  color: #1976d2;
  display: flex;
  justify-content: center;

}

.plan .title+* {
  margin-top: 0.75rem;
}

.plan .title-1 {
  font-weight: 400;
  font-size: 0.9rem;
  color: #0d0d0d;
}

.plan .title-2 {
  font-weight: 600;
  font-size: 1.7rem;
  color: #0d0d0d;
}

.plan .title-3 {
  font-weight: 500;
  font-size: 0.7rem;
  color: #dddddd;
  position: absolute;
  bottom: 60px;
}

.plan .title-4 {
  font-weight: 600;
  font-size: 1.3rem;
  color: #b4b5b5;
}

.plan .info+* {
  margin-top: 1rem;
}

.plan .features {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
}

.plan .features li {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.plan .features li+* {
  margin-top: 1rem;
}

.plan .features .item-feature {
  margin-bottom: 8px;
  text-align: left;
  font-size: 16px;

}

.plan .features .icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #1976d2;
  width: 20px;
  height: 20px;
  margin: -3px;
}

.plan .features .icon svg {
  width: 14px;
  height: 14px;
}

.plan .features+* {
  margin-top: 1.25rem;
}

.plan .action {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan .button {
  background-color: #4cb162;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  width: 100%;
  padding: 0.625em 0.75em;
  margin-top: 20px;
  height: "10px";
}

.plan .button:hover, .plan .button:focus {
  background-color: #4cb162;
}

.coin {
  font-size: 200px;
  width: 0.1em;
  height: 1em;
  background: linear-gradient(#faa504, #141001);
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: rotate_4001510 7s infinite linear;
  transform-style: preserve-3d;
}

.coin .side, .coin:before, .coin:after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  overflow: hidden;
  border-radius: 50%;
  right: -0.4em;
  text-align: center;
  line-height: 1;
  transform: rotateY(-90deg);
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.coin .tails, .coin:after {
  left: -0.4em;
  transform: rotateY(90deg);
}

.coin:before, .coin:after {
  background: linear-gradient(#faa504, #141001);
  backface-visibility: hidden;
  transform: rotateY(90deg);
}

.coin:after {
  transform: rotateY(-90deg);
}

@keyframes rotate_4001510 {
  100% {
    transform: rotateY(360deg);
  }
}

.svg_back {
  transform: scaleX(-1);
}

.img-jobpos {
  box-shadow: 0em 0em 2em 20px rgb(241, 237, 237);
}

.plan-Table {
  border-radius: 30px;
  color: #697e91;
  width: 100%;
}

.plan-Table .button-Table {
  background-color: #4cb162;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  width: 100%;
  padding: 0.625em 0.75em;
  height: "10px";
}


.title-Tablephone {
  font-weight: 600 !important;
  font-size: 1.5rem !important;
  color: #277cd8;
}

.plan-Table.title-Table1 {
  font-weight: 500;
  font-size: 1.5rem;
  color: #277cd8;
}

.plan-Table .title-Table-Detail {
  font-weight: 600;
  font-size: 1.3rem;
  color: #0d0d0d;
}

@media (min-width: 1200px) {
  .plan-Table .action-Table {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
 
  }
}
@media (max-width: 1200px) {
.plan-Table .action-Table {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px !important;
}
}

.plan-Table .button-Table:hover, .plan-Table .button-Table:focus {
  background-color: #4cb162;
}

.plan-Table .button-Table-Phone:hover, .plan-Table .button-Table-Phone:focus {
  background-color: #4cb162;
}
.plan-Table .button-Table-All {
  background-color: #259bdd;
  border-radius: 30px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  width: 100%;
  padding: 0.625em 0.75em;
  margin-top: 20px;
  height: "10px";
}

.plan-Table .button-Table-All:hover, .plan-Table .button-Table-All:focus {
  background-color: #259bdd;
}

.plan-Table-font {
  flex: 1;
  margin-top: "3%";
  padding: "10px"
}

.card-DetailPomotin-titlephone {
  font-size: 1.5rem !important;
  color: #3d4248;
  justify-items: center;
  justify-content: center;
  font-weight: 500;
}

.plan-box {
  border-radius: 30px;
  color: #f8f8f8;
  width: 100%;
}
.SliderNew {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.plan-Banner {
  border-radius: 30px;
  color: #697e91;
  width: 100%;
  margin-top: 40px;
}

.plan-Banner .inner-Banner {
  align-items: center;
  padding-top: 15px;
  background: white;
  box-shadow: 0em 0em 0em 1px rgb(162 162 162);
  border-radius: 30px;
  position: relative;

}

.plan-Banner .button-banner {
  background-color: #4cb162;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  width: 100%;
  padding: 0.625em 0.75em;
  margin-top: 20px;
  height: "10px";
}

.plan-Banner .button-banner:hover, .plan-Banner .button-banner:focus {
  background-color: #4cb162;
}

 /* หน้าจอคอม*/
@media (min-width: 1000px) {

  /* กล่องtitle หน้าService */
  .card {
    width: 50vw;
    height: 100px;
    background: white;
    border-radius: 25px;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display: flex;
    flex-direction: column;
    padding: 18px;
  }
  .shadow {
    box-shadow: 0em 0.2em 0.5em 0px rgb(192 192 192);
  }
  .ribbonNew {
    --r: .8em;
    border-inline: .5em solid #0000;
    padding: .5em .2em calc(var(--r) + .2em);
    clip-path: polygon(0 0, 100% 0, 100% 100%, calc(100% - .5em) 100%, 50% calc(100% - var(--r)), .5em 100%, 0 100%);
    background: radial-gradient(50% .2em at top, #000a, #0000) border-box, #142aa9 padding-box;
    width: 60px;
    height: 135px;
    margin-top: -80px;
  }
  .Icon-card {
    color: white;
    font-size: 2.5vw;
    margin-top: 70px;
    justify-content: center;
  }
  .card-title-Service {
    font-size: 2.5rem !important;
    color: #1c78db;
    justify-items: center;
    justify-content: center;
    font-weight: 600 !important;
  }
  .card-grid {
    margin-left: 0
  }
  .grid-tap {
    margin-top: 5%;
    margin-left: -43px !important;
  }
  .Table-fontsize {
    font-size: 2em !important;
  }
  .Table-iconfonsize {
    font-size: 3em;
    color: #ffda7c
  }
  .Table-IconJopost {
    font-size: 1.5em !important;
    color: #8cc0de;
    margin-bottom: 0.5em;
  }
  .Table-daysper {
    font-size: 0.9em !important;
  }
  .Table-cus {
    font-size: 0.8em !important;
  }
  .title-CardService {
    margin-top: -9% !important;
  }
  .title-CardServiceHeight {
    height: 13vh !important;
  }
  .Banner-Icon {
    font-size: 16px !important;
  }

   /* กล่องที่อยู่ */
  .plan-box .inner-box {
    align-items: center;
    padding: 40px;
    background: #f6f7f4;
    box-shadow: 0em 0.2em 0.2em 0px rgb(162 162 162);
    border-radius: 30px;
    position: relative;
    margin-bottom: 10px;
  }
  .box-address {
    font-size: 15px !important;
  }
  .icon-email {
    margin-left: 20px;
    margin-top: 8px;
  }
  .icon-address {
    margin-left: 20px;
  }

   /* กล่องโปรโมชั่น */
  .plan .inner {
    width: 80%;
    align-items: center;
    padding-top: 40px;
    background: white;
    box-shadow: 0em 0.2em 0.2em 0px rgb(162 162 162);
    border-radius: 30px;
  }

  .card-DetailPomotin {
    width: 100%;
    height: 100px;
    background: white;
    border-radius: 25px;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display: flex;
    flex-direction: column;
    padding: 15px !important;
  }
  .card-DetailPomotin-title {
    font-size: 2.5rem !important;
    color: #3d4248;
    justify-items: center;
    justify-content: center;
    font-weight: 500;
  }
  .inner-DetailPack {
    align-items: center;
    padding: 30px;
    padding-top: 40px;
    background: white;
    box-shadow: 0em 0.2em 0.2em 0px rgb(162 162 162);
    border-radius: 30px;
    position: relative;
  }
  .title-Table {
    font-weight: 600  !important;
    font-size: 2rem  !important;
    color: #277cd8;
  }
  .fontsize-Detail {
    font-size: 16px  !important;
  }
  .fontsize-BannerRemark{
    font-size: 14px  !important;
  }
  .fontsize-iconDetail{
    font-size: 70px  !important;
  }
  .icon-jobost{
   font-size:  50px  !important;
   color:#8cc0de ;
   margin-bottom: 20px  !important;
  }
  .icon-coin{
    font-size: 40px !important;
    color: #ffda7c;
  }
  .icon-banner{
    font-size: 40px !important;
    color: #9febaf;
  }


  .logobps-banner{
    width: 10%;
    display: block;
    margin-left: 10px !important;
    }

   
}



 /* หน้าจอIpad*/
@media (max-width: 1000px) {

    /* กล่องtitle หน้าService */
  .card {
    width: 100%;
    height: 70px;
    background: white;
    border-radius: 25px;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display: flex;
    flex-direction: column;
    padding: 15px;
  }
  .shadow {
    box-shadow: 0em 0.2em 0.5em 0px rgb(192 192 192);
  }
  .ribbonNew {
    --r: .8em;
    border-inline: .5em solid #0000;
    padding: .5em .2em calc(var(--r) + .2em);
    clip-path: polygon(0 0, 100% 0, 100% 100%, calc(100% - .5em) 100%, 50% calc(100% - var(--r)), .5em 100%, 0 100%);
    background: radial-gradient(50% .2em at top, #000a, #0000) border-box, #142aa9 padding-box;
    width: 50px;
    margin-top: -51px;
    height: 100px;
  }
  .Icon-card {
    color: white;
    font-size: 25px;
    margin-top: 50px;
  }
  .card-title-Service {
    font-size: 1.5rem !important;
    color: #1c78db;
    justify-items: center;
    justify-content: center;
    font-weight: 500 !important;
  }
  .card-grid {
    margin-left: 20px
  }
  .grid-tap {
    margin-top: 40px !important;
    margin-left: -20px !important;
  }

   /* ตารางแพ็กเกจปกติ */
  .Table-fontsize {
    font-size: 1.5em !important;
  }
  .Table-iconfonsize {
    font-size: 2em;
    color: #ffda7c
  }
  .Table-IconJopost {
    font-size: 1.5em !important;
    color: #8cc0de;
    margin-bottom: 0.5em;
  }
  .Table-daysper {
    font-size: 0.7em !important;
  }
  .Table-cus {
    font-size: 0.6em !important;
  }
  .title-CardService {
    margin-top: -7% !important;
  }
  .title-CardServiceHeight {
    height: 5vh !important;
  }
  .Banner-Icon {
    font-size: 10px !important;
  }

   /* กล่องที่อยู่ */
  .plan-box .inner-box {
    align-items: center;
    padding: 40px;
    background: #f6f7f4;
    box-shadow: 0em 0.2em 0.2em 0px rgb(162 162 162);
    border-radius: 30px;
    position: relative;
    margin-bottom: 10px;
  }
  .box-address {
    font-size: 15px !important;
  }
  .icon-email {
    margin-left: 20px;
    margin-top: 8px;
  }
  .icon-address {
    margin-left: 20px;
  }

 /* กล่องโปรโมชั่น */
  .plan .inner {
    width: 80%;
    align-items: center;
    padding-top: 40px;
    background: white;
    box-shadow: 0em 0.2em 0.2em 0px rgb(162 162 162);
    border-radius: 30px;
  }

  .card-DetailPomotin {
    width: 100%;
    height: 100px;
    background: white;
    border-radius: 25px;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display: flex;
    flex-direction: column;
    padding: 20px !important;
  }

  .card-DetailPomotin-title {
    font-size: 2.5rem !important;
    color: #3d4248;
    justify-items: center;
    justify-content: center;
    font-weight: 500;
  }

  .inner-DetailPack  {
    align-items: center;
    padding: 30px;
    padding-top: 40px;
    background: white;
    box-shadow: 0em 0.2em 0.2em 0px rgb(162 162 162);
    border-radius: 30px;
    position: relative;
  }

  .title-Table {
    font-weight: 600  !important;
    font-size: 2rem  !important;
    color: #277cd8;
  }
  .fontsize-Detail {
    font-size: 16px  !important;
  }
  .fontsize-BannerRemark{
    font-size: 14px  !important;
  }
  .fontsize-iconDetail{
    font-size: 70px  !important;
  }
  .icon-jobost{
    font-size:  50px  !important;
    color:#8cc0de ;
    margin-bottom: 20px  !important;
   }
   .fontsize-coin{
    font-size: 30px !important;
  }
  .icon-coin{
    font-size: 40px !important;
    color: #ffda7c;
  }

  .icon-banner{
    font-size: 40px !important;
    color: #9febaf;
  }

  .logobps-banner{
    width: 10%;
    display: block;
    margin-left: 10px !important;
    }

    
}


 /* หน้าจอIpad*/
@media (max-width: 760px) {

   /* กล่องTitel หน้าService */
  .card {
    width: 100%;
    height: 70px;
    background: white;
    border-radius: 25px;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display: flex;
    flex-direction: column;
    padding: 18px;
  }
  .shadow {
    box-shadow: 0em 0.2em 0.5em 0px rgb(192 192 192);
  }
  .ribbonNew {
    --r: .8em;
    border-inline: .5em solid #0000;
    padding: .5em .2em calc(var(--r) + .2em);
    clip-path: polygon(0 0, 100% 0, 100% 100%, calc(100% - .5em) 100%, 50% calc(100% - var(--r)), .5em 100%, 0 100%);
    background: radial-gradient(50% .2em at top, #000a, #0000) border-box, #142aa9 padding-box;
    width: 50px;
    margin-top: -54px;
    height: 100px;
  }
  .Icon-card {
    color: white;
    font-size: 25px;
    margin-top: 50px;

  }
  .card-title-Service {
    font-size: 1.5rem !important;
    color: #1c78db;
    justify-items: center;
    justify-content: center;
    font-weight: 500 !important;
  }
  .card-grid {
    margin-left: 20px
  }

 /* ตารางแพ็กเกจปกติ*/
  .Table-fontsize {
    font-size: 1.2em !important;
  }
  .Table-iconfonsize {
    font-size: 2em;
    color: #ffda7c
  }
  .Table-IconJopost {
    font-size: 1.5em !important;
    color: #8cc0de;
    margin-bottom: 0.5em;
  }
  .Table-daysper {
    font-size: 0.4em !important;
  }
  .Table-cus {
    font-size: 0.4em !important;
  }
  .title-CardService {
    margin-top: -7% !important;
  }
  .title-CardServiceHeight {
    height: 5vh !important;
  }
  .Banner-Icon {
    font-size: 7px !important;
  }

 /* กล่องที่อยู่ */
  .plan-box .inner-box {
    align-items: center;
    padding: 20px;
    background: #f6f7f4;
    box-shadow: 0em 0.2em 0.2em 0px rgb(162 162 162);
    border-radius: 30px;
    position: relative;
    margin-bottom: 10px;
  }
  .box-address {
    font-size: 8px !important;
    margin-top: 6px !important;
  }
  .icon-email {
    margin-left: 20px;
    margin-top: 8px;
  }
  .icon-address {
    margin-left: 20px;
  }

   /* กล่องโปรโมชั่น */
  .plan .inner {
    width: 90%;
    align-items: center;
    padding-top: 40px;
    background: white;
    box-shadow: 0em 0.2em 0.2em 0px rgb(162 162 162);
    border-radius: 30px;
  }
  .card-DetailPomotin {
    width: 100%;
    height: 80px;
    background: white;
    border-radius: 25px;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display: flex;
    flex-direction: column;
    padding: 22px !important;
  }

  .card-DetailPomotin-title {
    font-size: 1.5rem !important;
    color: #3d4248;
    justify-items: center;
    justify-content: center;
    font-weight: 500;
  }

  .inner-DetailPack{
    align-items: center;
    padding: 15px;
    padding-top: 30px;
    background: white;
    box-shadow: 0em 0.2em 0.2em 0px rgb(162 162 162);
    border-radius: 30px;
    position: relative;
  }

.title-Table {
  font-weight: 600 !important;
  font-size: 1.5rem !important;
  color: #277cd8;
  }
  .fontsize-Detail {
    font-size: 10px  !important;
  }

  .fontsize-BannerRemark{
    font-size: 10px  !important;
  }
  .fontsize-iconDetail{
    font-size: 60px  !important;
  }
  .icon-jobost{
    font-size:  30px  !important;
    color:#8cc0de ;
    margin-bottom: 10px  !important;
   }
   .fontsize-coin{
    font-size: 22px !important;
  }
  .icon-coin{
    font-size: 35px !important;
    color: #ffda7c;
  }
  .icon-banner{
    font-size: 35px !important;
    color: #9febaf;
  }


  .logobps-banner{
    width: 15%;
    display: block;
    margin-left: 10px !important;
    }


    
}


  /*หน้าจอโทรศัพท์ */
@media (max-width: 650px) {
  .grid-tap {
    margin-top: 40px !important;
    margin-left: -6px !important;
  }
  /*ตารางแพ็กเกจปกติ */
  .Table-fontsize {
    font-size: 1.2em !important;
  }
  .Table-iconfonsize {
    font-size: 2em;
    color: #ffda7c
  }
  .Table-IconJopost {
    font-size: 1.5em !important;
    color: #8cc0de;
    margin-bottom: 0.5em;
  }
  .Table-daysper {
    font-size: 0.4em !important;
  }
  .Table-cus {
    font-size: 0.4em !important;
  }

/* กล่องTitle */
  .title-CardService {
    margin-top: -7% !important;
  }
  .title-CardServiceHeight {
    height: 5vh !important;
  }

/* กล่องที่อยู่ */
  .plan-box .inner-box {
    align-items: center;
    padding: 20px;
    background: #f6f7f4;
    box-shadow: 0em 0.2em 0.2em 0px rgb(162 162 162);
    border-radius: 30px;
    position: relative;
    margin-bottom: 10px;
  }
  .Banner-Icon {
    font-size: 7px !important;
  }
  .box-address {
    font-size: 8px !important;
    margin-top: 6px !important;
  }
  .icon-email {
    margin-left: 5px;
    margin-top: 0px;
  }
  .icon-address {
    margin-left: 5px;
  }

 /* กล่องโปรโมชั่น */
  .plan .inner {
    width: 90%;
    align-items: center;
    padding-top: 40px;
    background: white;
    box-shadow: 0em 0.2em 0.2em 0px rgb(162 162 162);
    border-radius: 30px;
  }

  .card-DetailPomotin {
    width: 100%;
    height: 80px;
    background: white;
    border-radius: 25px;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display: flex;
    flex-direction: column;
    padding: 22px !important;
  }

  .card-DetailPomotin-title {
    font-size: 1.5rem !important;
    color: #3d4248;
    justify-items: center;
    justify-content: center;
    font-weight: 500;
  }

  .inner-DetailPack  {
    align-items: center;
    padding: 15px;
    padding-top: 30px;
    background: white;
    box-shadow: 0em 0.2em 0.2em 0px rgb(162 162 162);
    border-radius: 30px;
    position: relative;
 }
.title-Table {
  font-weight: 600 !important;
  font-size: 1.5rem !important;
  color: #277cd8;
}
.fontsize-Detail {
  font-size: 10px  !important;
}
.fontsize-BannerRemark{
  font-size: 10px  !important;
}
.fontsize-iconDetail{
  font-size: 40px  !important;
}
.icon-jobost{
  font-size:  30px  !important;
  color:#8cc0de ;
  margin-bottom: 10px  !important;
 }
 .fontsize-coin{
  font-size: 22px !important;
}
.icon-coin{
  font-size: 35px !important;
  color: #ffda7c;
}
.icon-banner{
  font-size: 35px !important;
  color: #9febaf;
}

.logobps-banner{
width: 15%;
display: block;
margin-left: 10px !important;
}



}


@media (min-width: 1100px) {
  .detail-terms{
    font-size: 13px !important;
  }
  
  .plan-Bannercontent {
    border-radius: 30px;
    color: #697e91;
    width: 100%;
    align-items: center;
    padding: 70px;
    padding-top: 40px;
    background: white;
    box-shadow: 0em 0.2em 0.2em 0px rgb(162 162 162);
    position: relative;
    margin-top: 15px;
  }
  .plan-Banner .inner-Banner1 {
    align-items: center;
    padding: 40px;
    padding-top: 40px;
    background: white;
    border-radius: 0px 0px 30px 30px;
    position: relative;
  }

  .plan-seach {
    height: 100px;
    color: #697e91;
    width: 40%;
    align-items: center;
    padding: 30px;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #cccccc;
  
  }

  .plan-banner1 {
    height: 100px;
    color: #697e91;
    width: 100%;
    align-items: center;
    padding: 20px;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #eeeeee;
    margin-top: 15px;
  }

  .plan-LastedJobs {
    height: 300px;
    color: #697e91;
    width: 100%;
    align-items: center;
    padding: 30px;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #cccccc;
    margin-top: 15px;
  }

  .plan-banner4 {
    height: 80px;
    color: #697e91;
    width: 100%;
    align-items: center;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #eeeeee;
    margin-top: 15px;
    padding: 10px;
  }

  .plan-Positions {
    height: 200px;
    color: #697e91;
    width: 100%;
    align-items: center;
    padding: 30px;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #cccccc;
    margin-top: 15px;
  }

  .plan-banner5 {
    height: 80px;
    color: #697e91;
    width: 60%;
    align-items: center;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #eeeeee;
    margin-top: 15px;
    padding: 10px;
  }

  .plan-LeadingCompany {
    height: 180px;
    color: #697e91;
    width: 100%;
    align-items: center;
    padding: 30px;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #cccccc;
    margin-top: 15px;
  }

  .plan-banner2 {
    height: 220px;
    color: #697e91;
    width: 100%;
    align-items: center;
    padding: 80px 10px 80px 10px;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #eeeeee;
    margin-top: 15px;
  }

  .plan-banner3 {
    height: 160px;
    color: #697e91;
    width: 100%;
    align-items: center;
    padding: 50px 10px 50px 10px;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #eeeeee;
    margin-top: 15px;
  }
   .banner1-fontsize{
font-size: 25px !important;
   }
   .banner-size{
    font-size: 13px!important;
   }
}



@media (max-width: 1100px) {
.detail-terms{
  font-size: 11px !important;
}

  .plan-Bannercontent {
    border-radius: 30px;
    color: #697e91;
    width: 100%;
    align-items: center;
    padding: 30px;
    padding-top: 40px;
    background: white;
    box-shadow: 0em 0.2em 0.2em 0px rgb(162 162 162);
    position: relative;
  }

  .plan-Banner .inner-Banner1 {
    align-items: center;
    padding: 10px;
    background: white;
    border-radius: 0px 0px 30px 30px;
    position: relative;
  }

  .plan-seach {
    height: 55px;
    color: #697e91;
    width: 50%;
    align-items: center;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #cccccc;
  
  }

  .plan-banner1 {
    height: 55px;
    color: #697e91;
    width: 100%;
    align-items: center;
    padding: 12px;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #eeeeee;
    margin-top: 10px;
  }

  .plan-LastedJobs {
    height: 150px;
    color: #697e91;
    width: 100%;
    align-items: center;
    padding: 30px;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #cccccc;
    margin-top: 10px;
  }

  .plan-banner4 {
    height: 45px;
    color: #697e91;
    width: 100%;
    align-items: center;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #eeeeee;
    margin-top: 10px;
    padding: 5px;
  }

  .plan-Positions {
    height: 130px;
    color: #697e91;
    width: 100%;
    align-items: center;
    padding: 30px;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #cccccc;
    margin-top: 10px;
  }

  .plan-banner5 {
    height: 45px;
    color: #697e91;
    width: 70%;
    align-items: center;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #eeeeee;
    margin-top: 10px;
    padding: 5px;
  }

  .plan-LeadingCompany {
    height: 120px;
    color: #697e91;
    width: 100%;
    align-items: center;
    padding: 30px;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #cccccc;
    margin-top: 10px;
  }

  .plan-banner2 {
    height: 115px;
    color: #697e91;
    width: 100%;
    align-items: center;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #eeeeee;
    margin-top: 10px;
    padding: 40px 10px 40px 10px;
  }

  .plan-banner3 {
    height: 80px;
    color: #697e91;
    width: 100%;
    align-items: center;
    padding: 25px 10px 25px 10px;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #eeeeee;
    margin-top: 10px;
  }

  .banner1-fontsize{
    font-size: 15px !important;
    }
    .banner-size{
     font-size: 10px!important;
    }
}

@media (max-width: 700px) {
  .detail-terms{
    font-size: 9px !important;
  }
  
  .plan-Bannercontent {
    border-radius: 30px;
    color: #697e91;
    width: 100%;
    align-items: center;
    padding: 15px;
    padding-top: 40px;
    background: white;
    box-shadow: 0em 0.2em 0.2em 0px rgb(162 162 162);
    position: relative;
  }
  .plan-banner4 {
    height: 45px;
    color: #697e91;
    width: 100%;
    align-items: center;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #eeeeee;
    margin-top: 10px;
    padding: 10px;
  }
  .plan-banner5 {
    height: 45px;
    color: #697e91;
    width: 70%;
    align-items: center;
    background: white;
    box-shadow: 0em 0em 0em 1px rgb(162 162 162);
    border-radius: 15px;
    position: relative;
    background-color: #eeeeee;
    margin-top: 10px;
    padding: 10px;
  }
  .banner1-fontsize{
    font-size: 11px !important;
    }
    .banner-size{
      font-size: 6px !important;
     }
}





.styled-card {
    border: 1px solid #ddd !important;
    width: 100%;
    position: 'relative';
    background-color: "#d7ebff";
    border-radius: "30px";
    padding: '15px !important';
}

.card-title {
    font-size: 1rem !important;
    color: #114189;
}



.card-title-ProfileNo {
    font-size: 1rem !important;
    color: #dc4747;
}


@media (min-width: 500px) {
    .container {
        height: 65px;
    }
    .container-div {
        margin-top: -60px;
    }
    .container-card {
        width: 480px;
        align-content: center;
    }
    .container-cardPro {
        width: 480px;
        align-content: center;
    }
    .container-cardEdit {
        width: 480px;
        align-content: center;
    }
    .container-cardProEdit {
        width: 480px;
        align-content: center;
    }
    .container-CardComplete {
        width: 130px;
        align-content: center;
    }
    .card-title-Profile {
        font-size: 0.8rem !important;
        color: #3fa255;
    }
    .title-Card {
        margin-top: -9% !important; 
      }
      .title-CardHeight{
        height: 5vh !important;
      }
}

@media (max-width: 1380px) {
    .container {
        height: 65px;
    }
    .container-div {
        margin-top: -60px;
    }
    .container-card {
        width: 740px;
        align-content: center;
    }
    .container-cardPro {
        width: 830px;
        align-content: center;
    }
    .container-cardProEdit {
        width: 830px;
        align-content: center;
    }
    .container-CardComplete {
        width: 130px;
        align-content: center;
    }
    .card-title-Profile {
        font-size: 1rem !important;
        color: #3fa255;
    }
    .title-Card {
        margin-top: -9% !important; 
      }
      .title-CardHeight{
        height: 13vh !important;
      }
}

@media (max-width: 1180px) {
    .container {
        height: 65px;
    }
    .container-div {
        margin-top: -60px;
    }
    .container-card {
        width: 600px;
        align-content: center;
    }
    .container-cardPro {
        width: 680px;
        align-content: center;
    }
    .container-cardProEdit {
        width: 680px;
        align-content: center;
    }
    .container-CardComplete {
        width: 130px;
        align-content: center;
    }
    .card-title-Profile {
        font-size: 1rem !important;
        color: #3fa255;
    }
    .title-Card {
        margin-top: -9% !important; 
      }
      .title-CardHeight{
        height: 13vh !important;
      }
    
}

@media (max-width: 1130px) {
    .container {
        height: 65px;
    }
    .container-div {
        margin-top: -60px;
    }
    .container-card {
        width: 465px;
        align-content: center;
    }
    .container-cardPro {
        width: 580px;
        align-content: center;
    }

    .container-cardProEdit {
        width: 580px;
        align-content: center;
    }
    .container-CardComplete {
        width: 130px;
        align-content: center;
    }
    .card-title-Profile {
        font-size: 1rem !important;
        color: #3fa255;
    }
    .title-Card {
        margin-top: -9% !important; 
      }
      .title-CardHeight{
        height: 5vh !important;
      }
}

@media (max-width: 950px) {
    .container {
        height: 65px;
    }
    .container-div {
        margin-top: -60px;
    }
    .container-card {
        width: 430px;
        align-content: center;
    }
    .container-cardPro {
        width: 550px;
        align-content: center;
    }
    .container-cardProEdit {
        width: 550px;
        align-content: center;
    }
    .container-CardComplete {
        width: 120px;
        align-content: center;
    }
    .card-title-Profile {
        font-size: 0.8rem !important;
        color: #3fa255;
    }
    .title-Card {
        margin-top: -9% !important; 
      }
      .title-CardHeight{
        height: 5vh !important;
      }
}


@media (max-width: 900px) {
    .container {
        height: 65px;
    }
    .container-div {
        margin-top: -60px;
    }
    .container-card {
        width: 300px;
        align-content: center;
    }
    .container-cardPro {
        width: 540px;
        align-content: center;
    }
    .container-cardProEdit {
        width: 540px;
        align-content: center;
    }
    .container-CardComplete {
        width: 130px;
        align-content: center;
    }
    .card-title-Profile {
        font-size: 0.8rem !important;
        color: #3fa255;
    }
    .title-Card {
        margin-top: -9% !important; 
      }
      .title-CardHeight{
        height: 13vh !important;
      }
}


@media (max-width: 820px) {
    .container {
        height: 65px;
    }
    .container-div {
        margin-top: -60px;
    }
    .container-card {
        width: 270px;
        align-content: center;
    }
    .container-cardPro {
        width: 480px;
        align-content: center;
    }
    .container-cardEdit {
        width: 400px;
        align-content: center;
    }
    .container-cardProEdit {
        width: 330px;
        align-content: center;
    }
    .container-CardComplete {
        width: 130px;
        align-content: center;
    }
    .card-title-Profile {
        font-size: 0.8rem !important;
        color: #3fa255;
    }
    .title-Card {
        margin-top: -9% !important; 
      }
      .title-CardHeight{
        height: 5vh !important;
      }
}

@media (min-width: 1400px) {
    .container {
        height: 70px;
    }
    .container-div {
        margin-top: -60px;
        margin-bottom: 7px;
    }
    .container-card {
        width: 870px;
        align-content: center;
    }
    .container-cardPro {
        width: 970px;
        align-content: center;
    }
    .container-cardProEdit {
        width: 970px;
        align-content: center;
    }
    .container-CardComplete {
        width: 130px;
        align-content: center;
    }
    .container-divNew {
       height: 40vh;
    }

    .card-title-Profile {
        font-size: 1rem !important;
        color: #3fa255;
    }
    .title-Card {
        margin-top: -9% !important; 
      }
      .title-CardHeight{
        height: 13vh !important;
      }
}



@media (max-width: 450px) {
    .container {
        height: 65px;
    }
    .container-div {
        margin-top: -60px;
    }
    .container-card {
        width: 110px;
        align-content: center;
    }
    .container-cardPro {
        width: 175px;
        align-content: center;
    }
    .container-CardComplete {
        width: 120px;
        align-content: center;
    }

    .container-cardEdit {
        width: 130px;
        align-content: center;
    }
    .container-cardProEdit {
        width: 130px;
        align-content: center;
    }
    .card-title-Profile {
        font-size: 0.8rem !important;
        color: #3fa255;
    }
    .title-Card {
        margin-top: -9% !important; 
      }
      .title-CardHeight{
        height: 5vh !important;
      }
}
.swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex !important;
}

.item-swiper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.swiper-pagination {
    bottom: 0px !important;
}

.swiper-pagination-bullet {
    background: #bdbdbd !important;
    opacity: 0.8 !important;
    height: 8px !important;
}

.bulletActiveClass {
    background: #7c7b7b !important;
    width: 16px !important;
    opacity: 1 !important;
  
}

.slider-container {
    min-height: 0;
    min-width: 0;
    margin-bottom: 40px;
    /* padding: 20px; */
}

.slick-track{
    display: flex !important;
}

.slick-slide {
    flex: 1 !important;
}

.slick-slider .slick-track, .slick-slider .slick-list{
    display: flex !important;
}

.slick-initialized .slick-slide{
    justify-content: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.slick-initialized .slick-slide > div{
    flex: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.slick-track:before, .slick-track:after{
    display: none;
}